@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	ul,
	ol {
		list-style: revert;
	}
}

:root {
	--vh: 100vh;
}

.full-height {
	height: calc(var(--vh, 1vh) * 100);
}

.animate-float {
	animation: 4s ease-in-out infinite;
}

/* Generate keyframes for each heart */
.animate-float {
	animation: 4s ease-in-out infinite;
}

.animation-delay-0 {
	animation-delay: 0s;
}

.animation-delay-500 {
	animation-delay: 0.5s;
}

.animation-delay-1000 {
	animation-delay: 1s;
}

@keyframes float-0 {
	0%,
	9%,
	100% {
		opacity: 0;
	}
	10% {
		opacity: 0.7;
		transform: translateY(0%) translateX(0%);
	}
	90% {
		opacity: 0;
		transform: translateY(1000%) translateX(1000%) scale(4);
	}
}

/* Keyframe float-1 */
@keyframes float-1 {
	0%,
	9%,
	100% {
		opacity: 0;
	}
	15% {
		opacity: 0.7;
		transform: translateY(0%) translateX(0%);
	}
	90% {
		opacity: 0;
		transform: translateY(1000%) translateX(-1000%) scale(4);
	}
}

/* Keyframe float-2 */
@keyframes float-2 {
	0%,
	9%,
	100% {
		opacity: 0;
	}
	20% {
		opacity: 0.7;
		transform: translateY(0%) translateX(0%);
	}
	90% {
		opacity: 0;
		transform: translateY(-1000%) translateX(1000%) scale(4);
	}
}

/* Keyframe float-3 */
@keyframes float-3 {
	0%,
	9%,
	100% {
		opacity: 0;
	}
	25% {
		opacity: 0.7;
		transform: translateY(0%) translateX(0%);
	}
	90% {
		opacity: 0;
		transform: translateY(-2000%) translateX(-600%) scale(4);
	}
}
